/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { ComponentType, useCallback, useEffect } from 'react';
import i18n from 'i18next';
import { Row, Col } from 'react-grid-system';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Cookies from 'js-cookie';
import { productsData, resetProductDetails, setProductForGetAQuoteLink } from '../../../store/product/actions';
import { resetStickyMenu } from '../../../store/stickyMenu/actions';
import USP from '../USP/index';
import VariantSelector from '../VariantSelector/index';
import ProductDetailsLabel from '../ProductDetailsLabel';
import ProductCarouselThumbnails from '../ProductCarouselThumbnails/index';
import ProductTitle from '../ProductTitle/index';
import { getFavoriteContactId } from '../../../store/favoriteContact/actions';
import { MASTER_PRODUCT_ENDPOINT } from '../productendpoint';
import { setLocalStorage } from '../../Common/CookieSettings/CookieUtils';
import { getLanguage } from '../../Common/CookieSettings/SetLanguageData';
import { SITE_LEISTER_TECHNOLOGIES, getSitecoreApiHost, renderShop } from '../../../AppGlobals';
import MetaDataForShare from '../../MetaDataForShare';
import { PRODUCT_PAGE_ROUTE } from '../../../AppRoutes';
import {
  filterProductsBySkuAndReduceProductVariants,
  getAllShopifyGlobalIdsFromProductItems,
  getShopProductsConditionUsed,
  getShopProductsConditionNew,
  getShopProductsByVariantGids,
  filterProductsByAvailableForSale,
  sortShopProductVariantsByPrice
} from '../../Shopify/lib/shopify/leisterHelpers';
import { COUNTRY_CODE, SHOPIFY_TEST_ACCESS_KEY_COOKIE_NAME } from '../../Common/CookieSettings/CookieConstants';
import AddToCartTeaser from '../../Shopify/AddToCartTeaser';
import BundleSectionLink from '../../Shopify/Bundle/BundleSectionLink';
import { ShopProduct } from '../../Shopify/lib/shopify/types';
import Markdown from 'react-markdown';

// We need to trick TS, to ignore types on this JS component
import * as HeroActionButtonModule from '../HeroActionButton';
import AutoHeight from '../../Common/AutoHeight/AutoHeight';
import { getLegalText } from '../../../utils/legalText';
import { getIsConditionUsedFromUrl } from '../../Shopify/lib/utils';
import { ProductItem } from '../types';
import {
  getProductWithLowestProductItemPimId,
  navigateToProductDetailsPage,
  getProductItemArticleNumberForProductPageRedirect
} from './ProductDetailsHeroFunctions';
const HeroActionButton: any = HeroActionButtonModule.default;

export type ProductDetailsHeroProps = {
  getFavoriteContactId: any;
  favoriteContactId: any;
  sitecoreContext: any;
  productPageLinkForGetAQuote: any;
  match: any;
  productItems: ProductItem[];
  selectedVariant: any;
  title: any;
  fields: any;
  brand: any;
  titleEn: any;
  metaDescription: any;
  media: any;
  subCategoryEn: any;
  categoryEn: any;
  favouriteContactId: any;
  isLoading: boolean;
  setProductForGetAQuoteLink: (currentPageLink: string) => void;
  resetStickyMenu: () => void;
  resetProductDetails: () => void;
  setProductsJson: (product_name: any, articleNumber: any, countryCode: any, language: any, tenant: any) => void;
  history: RouteComponentProps['history'];
};

const ProductDetailsHero: React.FC<ProductDetailsHeroProps> = props => {
  const [language, setLanguage] = React.useState(getLanguage(props.sitecoreContext));
  const [countryCode, setCountryCode] = React.useState('');
  const [shopifyTestAccessKey, setShopifyTestAccessKey] = React.useState('');
  const [shouldRenderShop, setShouldRenderShop] = React.useState(false);
  const [shopProducts, setShopProducts] = React.useState<ShopProduct[]>([]);
  const [shopProductLoading, setShopProductLoading] = React.useState(false);

  const handleProducts = useCallback(() => {
    function setProductsOnRedux() {
      const { match } = props;
      if (match && match.params && match.params.product_name) {
        const currentArticleNumber = match.params.article_number
            ? match.params.article_number.toString().replace('-', '.')
            : null,
          historyStateArticleNumber =
            window.history.state && window.history.state.articleNr
              ? window.history.state.articleNr.toString().replace('-', '.')
              : null,
          countryCode = Cookies.get(COUNTRY_CODE)?.toLowerCase();

        const prevArticleNumber = props.selectedVariant?.articleNumber ?? undefined;

        // Only set products when article number changed
        if (currentArticleNumber !== prevArticleNumber) {
          // First clear the existing product details
          props.resetProductDetails();

          // Then request and set the new product details
          props.setProductsJson(
            match.params.product_name,
            historyStateArticleNumber || currentArticleNumber,
            countryCode,
            getLanguage(props.sitecoreContext),
            props.sitecoreContext.site.name
          );
        }
      }
    }
    function setProductPathNameToLocalStorage(pathname: string) {
      triggerPageViewEvent();
      setLocalStorage('currentPageLocation', pathname, 'necessary');
    }
    const triggerPageViewEvent = () => {
      const { title, selectedVariant } = props;
      const pageUrl = document.location.origin + document.location.pathname + document.location.search,
        pageTitle = selectedVariant ? title + ' ' + selectedVariant.articleNumber : title;
      if (pageUrl && pageTitle) {
        window.dataLayer.push({
          event: 'pageview',
          page: {
            url: pageUrl,
            title: pageTitle
          }
        });
      }
    };

    setProductsOnRedux();
    const currentPagePathname = window.location.pathname;
    setProductPathNameToLocalStorage(currentPagePathname);
  }, [props.match]);

  useEffect(() => {
    props.resetStickyMenu();
    handleProducts();
    const currentPageLink = window.location.href;
    const { getFavoriteContactId, favoriteContactId, sitecoreContext } = props;

    getFavoriteContactId(favoriteContactId, sitecoreContext);
    add3DScriptsToBody();
    setCountryCode(Cookies.get(COUNTRY_CODE)?.toLowerCase() ?? '');
    setShopifyTestAccessKey(Cookies.get(SHOPIFY_TEST_ACCESS_KEY_COOKIE_NAME) ?? '');

    if (props.productPageLinkForGetAQuote !== currentPageLink) {
      props.setProductForGetAQuoteLink(currentPageLink);
    }
  }, [props.match]);

  useEffect(() => {
    setProductNameToLocalStorage(props.title);
  }, [props.title]);

  useEffect(() => {
    const currentLanguage = getLanguage(props.sitecoreContext);
    setLanguage(currentLanguage);
  }, [props.sitecoreContext]);

  useEffect(() => {
    let mounted = true;

    async function shouldRenderShop() {
      if (mounted) {
        const { site } = props.sitecoreContext;
        const siteName = site?.name || SITE_LEISTER_TECHNOLOGIES;
        const shouldRenderShop = await renderShop(countryCode, shopifyTestAccessKey, siteName);
        setShouldRenderShop(shouldRenderShop);
      }
    }

    shouldRenderShop();

    return () => {
      mounted = false;
    };
  }, [countryCode, shopifyTestAccessKey, props.sitecoreContext]);

  useEffect(() => {
    async function checkAvailableShopProducts() {
      setShopProductLoading(true);

      const shopifyGlobalIds = getAllShopifyGlobalIdsFromProductItems(props.productItems);
      const shopProducts = await getShopProductsByVariantGids(shopifyGlobalIds);

      setShopProducts(shopProducts);
      setShopProductLoading(false);
    }

    if (shouldRenderShop && props.productItems.length > 0) {
      checkAvailableShopProducts();
    }
  }, [shouldRenderShop, props.productItems]);

  useEffect(() => {
    if (props.isLoading) {
      return;
    }

    if (!props.titleEn) {
      return;
    }

    // If there's no shop to render, just search for product item with lowest id and navigate to it
    if (!shouldRenderShop && props.productItems.length > 0 && !props.selectedVariant) {
      const productItemWithLowestId = getProductWithLowestProductItemPimId(props.productItems);

      if (productItemWithLowestId) {
        const { articleNumber } = productItemWithLowestId;

        navigateToProductDetailsPage(props.history, language, props.titleEn, articleNumber);
      }
    }
  }, [
    shouldRenderShop,
    props.productItems,
    props.selectedVariant,
    props.titleEn,
    language,
    props.history,
    props.isLoading
  ]);

  useEffect(() => {
    if (props.isLoading) {
      return;
    }

    if (shopProductLoading) {
      return;
    }

    if (!props.titleEn) {
      return;
    }

    if (shouldRenderShop && props.productItems.length > 0 && !props.selectedVariant) {
      // Try to find the correct Product Item to redirect to
      // Should match the logic for target article number on the lister pages(defined in backend)
      const redirectToArticleNumber = getProductItemArticleNumberForProductPageRedirect(
        props.productItems,
        shopProducts
      );
      if (redirectToArticleNumber) {
        navigateToProductDetailsPage(props.history, language, props.titleEn, redirectToArticleNumber);
      }
    }
  }, [
    shouldRenderShop,
    props.productItems,
    props.selectedVariant,
    shopProducts,
    props.isLoading,
    language,
    props.history,
    props.titleEn,
    shopProductLoading
  ]);

  async function add3DScriptsToBody() {
    await import('@google/model-viewer');
  }

  function setProductNameToLocalStorage(title: string) {
    setLocalStorage('currentPageTitle', title);
  }

  // We should not render the component until the data is loaded, otherwise we experience layout shifts
  const componentDataLoaded = props.title !== null || props.titleEn !== '';

  const {
      'Product Details Label': productLabel,
      'Get A Quote Page Link': getAQuotePageLink,
      'Find a Dealer': dealerLink,
      'Talk to an Expert': expertLink
    } = props.fields,
    {
      brand,
      title,
      titleEn,
      metaDescription,
      media,
      selectedVariant,
      fields,
      subCategoryEn,
      categoryEn,
      favouriteContactId
    } = props,
    subjectEmail = selectedVariant ? title + ' ' + selectedVariant.articleNumber : title,
    getAQuoteLink = getAQuotePageLink && getAQuotePageLink.value && getAQuotePageLink.value.href,
    imageType = 'image/jpeg',
    metaDataImage = media && media.length > 0 ? media.find((item: any) => item.type === imageType && item.url) : [],
    masterProductPageUrl = `/${language}${PRODUCT_PAGE_ROUTE}/${titleEn.replace(/ /g, '-')}`,
    masterProductPageLink = getSitecoreApiHost() + masterProductPageUrl,
    productItem = selectedVariant,
    // Hide the add to cart teaser, if we are on a product detail page (not item), without any product items
    hideAddToCartTeaser = !productItem && !!props.productItems.length;

  const shopProductsWithCurrentSku = filterProductsBySkuAndReduceProductVariants(
    shopProducts,
    productItem?.articleNumber
  );
  const shopProductConditionNew = getShopProductsConditionNew(shopProductsWithCurrentSku)[0]; // There should be only 1 now anyway;
  const shopProductVariantConditionNew = shopProductConditionNew?.variants[0] ?? undefined; // Variants are already filtered by sku, so only 1 should be present
  const shopProductsConditionUsed = getShopProductsConditionUsed(shopProductsWithCurrentSku);

  /**
   * Renders legal text based on the country code and the presence of a shop product.
   * @returns {string|boolean} The legal text to be rendered, or false if no rendering is needed.
   */
  const renderLegalText = useCallback(() => {
    const renderLegalText = getLegalText(countryCode) ?? '';
    const hasShopProduct = !!shopProductVariantConditionNew || shopProductsConditionUsed.length > 0;
    // TODO: we should probably check for availability as well, on the demo products
    // !!shopProductsConditionUsed.find(product => product.availableForSale === true);
    return hasShopProduct && renderLegalText;
  }, [countryCode, shopProductVariantConditionNew, shopProductsConditionUsed]);

  // The default AddToCartTeaser rendering, for the original products
  const addToCartTeaser = (
    <AddToCartTeaser
      productData={{ brand, category: categoryEn, subcategory: subCategoryEn, name: titleEn }}
      productItem={productItem}
      shopProduct={shopProductConditionNew}
      shopProductVariant={shopProductVariantConditionNew}
      noImage
      findADealerProps={{
        hasFavouriteContact: !!favouriteContactId,
        getAQuoteLink,
        dealerLink,
        expertLink,
        shouldRenderShop
      }}
      shouldRenderShop={shouldRenderShop}
    />
  );

  // The AddToCartTeasers rendering, for the demo products (this could be theoretically more than one)
  const addToCartTeasersUsedProducts =
    shopProductsConditionUsed.length > 0 &&
    shopProductsConditionUsed.map(usedProduct => (
      <AddToCartTeaser
        key={usedProduct.id}
        productData={{ brand, category: categoryEn, subcategory: subCategoryEn, name: titleEn }}
        productItem={productItem}
        shopProduct={usedProduct}
        shopProductVariant={usedProduct?.variants.find(v => v.sku === productItem?.articleNumber)}
        noImage
        tags={[i18n.t('SHOP | Demo device tag')]}
        findADealerProps={{
          hasFavouriteContact: !!favouriteContactId,
          getAQuoteLink,
          dealerLink,
          expertLink,
          shouldRenderShop
        }}
      />
    ));

  // Get the condition attribute from the URL
  const onConditionUsed = getIsConditionUsedFromUrl();

  // Define the order of items to show, if we are on the condition used view, we want to show the used products first
  const topAddToCartTeaserPlaceholder = onConditionUsed ? addToCartTeasersUsedProducts : addToCartTeaser;
  const bottomAddToCartTeaserPlaceholder = onConditionUsed ? addToCartTeaser : addToCartTeasersUsedProducts;

  return componentDataLoaded ? (
    <div className='ProductDetailsHero'>
      <MetaDataForShare
        showPageData
        title={title}
        currentArticleNumber={selectedVariant && selectedVariant.articleNumber}
        metaDescription={metaDescription}
        image={metaDataImage.url}
        variantItem={selectedVariant}
        masterProductLink={masterProductPageLink}
      />
      <Row nogutter className='ProductDetailsHero-Row componentContainer'>
        <Col xl={6} lg={6} className='ProductDetailsHero-Row-Left'>
          <div className='ProductDetailsHero-Row-Left-Gallery'>
            <ProductCarouselThumbnails />
          </div>
        </Col>
        <Col xl={6} lg={6} className='ProductDetailsHero-Row-Right'>
          <ProductTitle title={title} />
          <USP />
          <ProductDetailsLabel label={productLabel} />
          <AutoHeight>
            {!props.isLoading && !shopProductLoading ? (
              <div className='ProductDetailsHero-RenderShop'>
                <VariantSelector
                  shopProducts={shopProducts}
                  currentArticleNumber={selectedVariant?.articleNumber}
                  masterProductPageUrl={masterProductPageUrl}
                  key={onConditionUsed ? 'used' : 'new'}
                  shouldRenderShop={shouldRenderShop}
                />

                {!hideAddToCartTeaser && topAddToCartTeaserPlaceholder}
                {!hideAddToCartTeaser && bottomAddToCartTeaserPlaceholder}

                {renderLegalText() && (
                  <Markdown className='LegalText Markdown--support-links'>{renderLegalText()}</Markdown>
                )}

                {shouldRenderShop && <BundleSectionLink />}
              </div>
            ) : null}
          </AutoHeight>
          <HeroActionButton
            hasBuyOption={shouldRenderShop && shopProductVariantConditionNew?.availableForSale}
            subjectEmail={subjectEmail}
            fields={fields}
            getAQuotePageLink={getAQuoteLink}
            categoryEn={categoryEn}
            favouriteContact={favouriteContactId}
          />
        </Col>
      </Row>
    </div>
  ) : (
    // The following placeholder, will simply improve the visual appearance of the page.
    // This because it will not show the images/content on the screen which are already loaded below.
    // This drastically improves the visual appearance of the page and the loading product details.
    <div style={{ height: '100vh' }} />
  );
};

const mapStateToProps = (state: any) => {
  const { masterProductData, variants, selectedVariant, isLoading } = state.productDetails;

  return {
    isLoading: isLoading ?? false,
    title: masterProductData ? masterProductData.MasterProductName : null,
    titleEn: masterProductData ? masterProductData.MasterProductENName : '',
    metaDescription: masterProductData ? masterProductData.MasterProductMetaDescription : null,
    selectedVariant,
    media: masterProductData ? masterProductData.media : null,
    productPageLinkForGetAQuote: state.productDetails.productPageLinkForGetAQuote,
    categoryEn: masterProductData ? masterProductData.CategoryEn : null,
    subCategoryEn: masterProductData ? masterProductData.SubCategoryEn : null,
    favouriteContactId: state.favoriteContact && state.favoriteContact.favoriteContactId,
    productItems: variants ? variants : null,
    category: masterProductData ? masterProductData.Category : null,
    brand: masterProductData ? masterProductData.BrandName : null
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setProductsJson: (product_name: any, articleNumber: any, countryCode: any, language: any, tenant: any) =>
      dispatch(
        productsData(
          MASTER_PRODUCT_ENDPOINT(product_name, language, tenant),
          articleNumber,
          countryCode,
          language,
          tenant
        )
      ),
    resetProductDetails: () => dispatch(resetProductDetails()),
    resetStickyMenu: () => dispatch(resetStickyMenu()),
    setProductForGetAQuoteLink: (link: any) => dispatch(setProductForGetAQuoteLink(link)),
    getFavoriteContactId: (id: any, sitecoreContextFields: any) =>
      dispatch(getFavoriteContactId(id, sitecoreContextFields))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter<
    RouteComponentProps & ProductDetailsHeroProps,
    ComponentType<RouteComponentProps & ProductDetailsHeroProps>
  >(withSitecoreContext()(ProductDetailsHero))
);
